.dnW6MFdc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

._18vVUO_0 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 20px;
  margin-top: 2px;
}

._18vVUO_0 ._3GZEg_VM {
    font-size: 17.6px;
    font-size: 1.1rem;
  }

._18vVUO_0 ._3Mr4oSTw {
    color: #82828c;
    font-size: 14.4px;
    font-size: 0.9rem;
  }

._18vVUO_0 .IQGcs-g9 {
    color: #82828c;
    font-size: 14.4px;
    font-size: 0.9rem;
    margin-top: -6px;
    margin-bottom: 12px;
  }

._1nKXHvtt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

._1nKXHvtt > div {
    text-align: center;
  }

._1RErRfz- {
  padding: 6.4px 9.6px;
  padding: 0.4rem 0.6rem;
}
