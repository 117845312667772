._1g45OTqw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 35px;
}

._32cmi4Uj {
  width: 80%;
}

._2iayFC7B {
  border: 1px solid #e4e4e6;
  border-radius: 15px;
  overflow: hidden;
  height: 180px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  background-size: fit
}

._2iayFC7B:hover {
  border: 1px solid #c0c0c4;
  background: #f7f7fa;
}

._2iayFC7B._45p6oCz4 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  pointer-events: none;
}

._2iayFC7B.tRmMdb3L {
  background-color: #fff;
}

._2iayFC7B ._27LSob-7 {
    cursor: pointer;
    pointer-events: auto;
}

._2iayFC7B ._27LSob-7:hover ._2f9570_Y {
        color: #ff7040;
}

._2iayFC7B ._27LSob-7 ._2f9570_Y {
      color: #e84b17;
      font-size: 40px;
      position: relative;
      top: -3px;
}

._2iayFC7B ._1DGuNz-i {
    font-size: 14px;
    color: #82828c;
    text-align: center;
}

._2iayFC7B ._1DGuNz-i ._2JIKkxDp {
      font-size: 20px;
      margin-bottom: 10px;
    }

._2ynZX4eA {
  position: absolute;
  bottom: -7px;
}

._3BYPgZXN {
  text-align: center;
}
