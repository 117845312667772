._3YMew1iZ {
  margin-bottom: 30px;
}

  ._3YMew1iZ ._2NEN_g3e {
  }

._1cX4WVMY > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 4px;
    padding: 6px 15px;
    color: #212529;
  }

._1cX4WVMY > * svg,
    ._1cX4WVMY > * path {
      fill: #212529;
    }

._1cX4WVMY > *:hover {
  color: #007bff;
}

._1cX4WVMY > *:hover svg,
      ._1cX4WVMY > *:hover path {
        fill: #007bff;
}

._1cX4WVMY ._2YYfVmRh {
    background: #f0c52c;
    color: #fff;
  }

._1cX4WVMY ._2YYfVmRh ._20BZE37i {
      color: #fff;
    }

._1cX4WVMY ._2YYfVmRh ._20BZE37i svg,
      ._1cX4WVMY ._2YYfVmRh ._20BZE37i path {
        fill: #fff;
      }

._1cX4WVMY ._2YYfVmRh:hover {
  color: #fff;
  text-decoration: none;
}

._1cX4WVMY:not(:last-child) {
  margin-bottom: 10px;
}

._20BZE37i {
  margin-right: 12px;
  position: relative;
  top: -2px;
}

._3ZvUU8VF {
  padding: 30px;
}

._3ZvUU8VF h3 {
    margin-bottom: 20px;
  }

._1RYG-WRt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

._1RYG-WRt ._2lSNuHDY {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }

._1FImf832 {
  width: 100px;
  margin-left: 20px;
  cursor: pointer
}

._1FImf832:hover {
  opacity: 0.8;
}

._1FImf832 img {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e4e4e6;
}

._1FImf832 ._2d5lLw8d {
    color: #007bff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 5px;
    font-size: 14px;
}
