._1F0dlyNJ {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 85px;
  height: 40px
}
._1F0dlyNJ._3qrPM2nN > * {
  border-color: #e84b17;
}
._1F0dlyNJ._1ZQsvyEo > * {
  pointer-events: none
}
._1F0dlyNJ._1ZQsvyEo > *:first-child {
  border-radius: 4px;
}
._1F0dlyNJ._1ZQsvyEo > *:last-child {
  display: none;
}
._1F0dlyNJ > * {
    -webkit-box-flex: 1;
        -ms-flex: 1 0;
            flex: 1 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f7f7fa;
    border: 1px solid #e4e4e6;
    cursor: pointer;
    font-size: 15px
}
._1F0dlyNJ > *._23eVVDlv {
  color: #fff;
}
._1F0dlyNJ > *:hover {
  background-color: rgba(130, 130, 140, 0.14);
}
._1F0dlyNJ > *:first-child {
  border-radius: 4px 0 0 4px
}
._1F0dlyNJ > *:first-child._23eVVDlv {
  background: #68b601;
  border-color: #68b601;
}
._1F0dlyNJ > *:last-child {
  border-left: none;
  border-radius: 0 4px 4px 0
}
._1F0dlyNJ > *:last-child._23eVVDlv {
  background: #ff7040;
  border-color: #ff7040;
}
._1F0dlyNJ ._3K0amx8s {
    display: none;
}
