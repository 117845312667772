._2TaU4AHC > * {
  width: 90%;
  max-width: 500px;
}

  ._2TaU4AHC > * ._3xWexZgZ {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e4e4e6;
    background-color: #f7f7fa;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 15px;
  }

  ._2TaU4AHC > * ._3xWexZgZ img {
      opacity: 0;
      width: 100%;
      max-height: 45vh;
    }

  ._2TaU4AHC > * ._1pGJEXZK {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
  }

  ._2TaU4AHC > * ._1pGJEXZK ._3sYyUrsQ {
      font-size: 25px;
      color: #e84b17;
      margin-bottom: 10px;
    }

  ._2TaU4AHC > * ._1pGJEXZK .iOTdL-bR {
      text-align: center;
      font-size: 14px;
    }

._2OKiSvpA {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .9);
  color: #fff;
  font-size: 24px;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

._2OKiSvpA ._1cUL-5u3 {
    width: 80%;
    max-width: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

._2OKiSvpA ._3PsyJP86 {
    position: static;
    -webkit-transform: none;
            transform: none;
    margin-bottom: 20px;
  }

._2OKiSvpA ._1goHV52d {
    width: 100%;
    height: 30%;
    border: 3px solid #fff;
    border-radius: 100px;
    padding: 4px;
  }

._2OKiSvpA ._1goHV52d ._3OzqgLGJ {
      background: #fff;
      max-width: 100%;
      height: 6px;
      border-radius: 6px;
      -webkit-transition: width 0.15s ease-out;
      transition: width 0.15s ease-out;
    }
