._14V7n0_U {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (max-width: 575.98px) {

  ._14V7n0_U {
    font-size: 12px;
  }
}

._14V7n0_U > * {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.Xn9kcV7p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pBx6MR8O {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pBx6MR8O img {
    margin-right: 20px;
  }

._4K1Kf09L {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

._4K1Kf09L ._3Vd_zxgL {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
  }

._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO {
      border: none;
      background: transparent;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      min-height: 120px;
      border: 1px solid #e4e4e6;
      border-radius: 15px;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1
    }

._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO:first-child {
  margin-right: 20px;
}

._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO:focus,
      ._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO:active,
      ._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO:hover {
  border-color: #c0c0c4;
  outline: none;
}

._4K1Kf09L ._3Vd_zxgL ._1yrvlhpO._1fF1oznr {
  border-color: #007bff;
  background: rgba(0, 123, 255, 0.05);
  color: #007bff;
}
