._1TmKAtY3 {
  max-width: 500px;
}

._1Oc07vcY {
  background: #fff;
  margin-top: 150px;
  padding: 30px;
  border-radius: 4px;
}

@media (max-width: 575px) {

  ._1Oc07vcY {
    margin-top: 60px;
    border-radius: 0;
  }
}

._1Oc07vcY:before {
  content: '';
  display: block;
  height: 300px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('/static/img/home-banner2.jpg') no-repeat center 62%;
  background-size: cover;
}

@media (max-width: 700px) {

  ._1Oc07vcY:before {
    background-position: center 85%;
  }
}

@media (max-width: 575px) {

  ._1Oc07vcY:before {
    height: 200px;
    background-position: center 100%;
  }
}

@media (max-width: 500px) {

  ._1Oc07vcY:before {
    background-position: center 120%;
  }
}

@media (max-width: 440px) {

  ._1Oc07vcY:before {
    background-position: center 140%;
  }
}

@media (max-width: 400px) {

  ._1Oc07vcY:before {
    background-position: center 200%;
  }
}

@media (max-width: 320px) {

  ._1Oc07vcY:before {
    background-position: center 600%;
  }
}
