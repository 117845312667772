._2F0hLKmD {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-right: 15px;
}

@media (max-width: 768px) {
  ._2F0hLKmD {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

._2k3Ci0ST {
  margin-right: 5px;
}

._2LQU202F {
  color: #dc3545;
}
