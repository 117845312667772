._1FauWq9q {
  width: 100%;
  max-width: 400px;
  border: 1px solid #e4e4e6;
  border-radius: 15px;
  margin: 20px auto 50px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 40px 20px;
}

._1fedh0lZ {
  text-align: center;
}

.gAnOwhG6 {
  text-align: center;
}
