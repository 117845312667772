.cb66Mfoa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative
}
.cb66Mfoa:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  background: #e4e4e6;
}
.cb66Mfoa ._1RXFiA2f {
    background: #fff;
    padding: 0 15px;
    z-index: 1;
}
