.hklbYVoN {
  background: url('/static/img/home-banner2.jpg') no-repeat;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.050000000000000044)), to(rgba(0, 0, 0, 0.4))),
    url('/static/img/home-banner2.jpg') no-repeat center center;
  background: linear-gradient(rgba(0, 0, 0, 0.050000000000000044), rgba(0, 0, 0, 0.4)),
    url('/static/img/home-banner2.jpg') no-repeat center center;
  background-size: cover;

  min-height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  .hklbYVoN ._12A0QHka {
    color: #fff;
    text-align: center;
    max-width: 600px;
    z-index: 1;
  }

  .hklbYVoN ._12A0QHka h1 {
      font-family: 'Bebas Neue', sans-serif;
      font-size: 52px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, .33);
    }

  .hklbYVoN ._12A0QHka h4 {
      text-shadow: 0px 0px 4px rgba(0, 0, 0, .66);
    }

.xxPJz0lV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 30px 0 40px 0
}

.xxPJz0lV > a {
  margin: 0 15px;
  display: block;
  width: 70px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  fill: #fff;
}

._2f48mKc6 h6 {
    color: #82828c;
    margin: 0;
  }

._2t5pQRtr,
._2Ag7n645 img {
  width: 100%;
}

._3mhkK-m7 {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

._3mhkK-m7 img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
