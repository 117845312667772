._36iabpMH {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  z-index: 0;
  -webkit-transition: opacity 0.66s;
  transition: opacity 0.66s;
}
