._1VdCz58- {
  border-bottom: 2px solid #e4e4e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 20px;
}

._3wWc0gfE {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: white;
  border: none;
  margin-right: 10px;
  padding: 10px 15px;
  position: relative
}

._3wWc0gfE._1I-jV0cH {
  cursor: pointer;
}

._3wWc0gfE._1I-jV0cH:hover {
  background: #f7f7fa;
}

._3wWc0gfE._1I-jV0cH._3J_IyzMw,
    ._3wWc0gfE._1I-jV0cH:focus {
  outline: none;
}

._3wWc0gfE._1I-jV0cH._3J_IyzMw:after, ._3wWc0gfE._1I-jV0cH:focus:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #f0c52c;
  z-index: 1;
  position: absolute;
  bottom: -2px;
  left: 0;
}

._3wWc0gfE._3J_IyzMw {
  -webkit-box-shadow: 0 2px 0 0 #f0c52c;
          box-shadow: 0 2px 0 0 #f0c52c;
  pointer-events: none;
}
