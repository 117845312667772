._37FtPTZz {
  background: #f0c52c;
  color: #fff;
  padding-bottom: 30px;
  margin-top: 50px;
}

  ._37FtPTZz h3 {
    font-family: 'Bebas Neue', Helvetica;
    font-size: 35px;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  ._37FtPTZz p {
    margin-bottom: 10px;
  }

  ._37FtPTZz ._2euvdOo5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 10px;
  }

  ._37FtPTZz ._2euvdOo5 ._2j2X9bqN {
      font-size: 22px;
      margin-right: 10px;
    }

  ._37FtPTZz a {
    color: #fff
  }

  ._37FtPTZz a._12QHpe8m {
  text-decoration: underline;
}
