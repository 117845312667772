._3BzML1os {
  margin-bottom: 40px;
}
  ._3BzML1os ._2XVfY3Nu {
    font-size: 22.4px;
    font-size: 1.4rem;
  }

.QRc2bM2R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.QRc2bM2R ._2YqMYZxn {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    font-size: 12px;
    margin: 0 10px;
  }

.QRc2bM2R ._1iPwa4UO {
    color: #82828c;
    font-size: 14.4px;
    font-size: 0.9rem;
  }

.QRc2bM2R ._1BT1Y1KT {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }

.QRc2bM2R ._2XVfY3Nu {
    font-size: 17.6px;
    font-size: 1.1rem;
  }

.QRc2bM2R .SgSrY25o {
    margin-left: 26px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

.QRc2bM2R .SgSrY25o ._1cFYBfSe {
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
