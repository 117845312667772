.kP-N3gYh {
  font-family: 'Bebas Neue', Arial, Helvetica, sans-serif;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.kP-N3gYh:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: calc(50% - 4px);
  background: #f0c52c;
}

._3P3TOtUg {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

._3STod7aG {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

._2kqwmzA1 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

._6z-TsUl2 {
  z-index: 1;
  background: #fff;
}

._3P3TOtUg ._6z-TsUl2, ._2kqwmzA1 ._6z-TsUl2 {
    padding-right: 30px;
  }

._3STod7aG ._6z-TsUl2, ._2kqwmzA1 ._6z-TsUl2 {
    padding-left: 30px;
  }
