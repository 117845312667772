._3CMJVwGC {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

._2dbUr7jN {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0;
}

.FTpRPwVc {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #ccc;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.U5wcfIn- .FTpRPwVc {
    opacity: 0.33;
    cursor: not-allowed;
  }

.FTpRPwVc ._1toXWG5P {
    display: block;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    -webkit-transition: all 0.08s ease-out;
    transition: all 0.08s ease-out;
  }

.FTpRPwVc._3-5IFDI5 {
  background: #68b601;
}

.FTpRPwVc._3-5IFDI5 ._1toXWG5P {
      left: auto;
}

._1vwa6JWc .FTpRPwVc {
    height: 26px;
    width: 50px;
    padding: 2px;
    border-radius: 13px;
  }

._1vwa6JWc ._1toXWG5P {
    height: 22px;
    width: 22px;
    top: 2px;
    left: 2px;
  }

._1vwa6JWc ._3-5IFDI5 ._1toXWG5P {
    left: 26px;
  }

._2nM0Zg5L .FTpRPwVc {
    height: 18px;
    width: 32px;
    padding: 2px;
    border-radius: 9px;
  }

._2nM0Zg5L ._1toXWG5P {
    height: 14px;
    width: 14px;
    top: 2px;
    left: 2px;
  }

._2nM0Zg5L ._3-5IFDI5 ._1toXWG5P {
    left: 16px;
  }

._4dB0nQs {
  color: #82828c;
  margin-left: 5px;
}

._2-WGlNWJ {
  color: red;
}
